
import Vue from 'vue';
import { format } from 'date-fns';
import fileBasedLayout from './filebased.vue';
import { LayoutSection, ValidComponents, CurrentSection } from '../../types/layout';
import AppGlobals from '../global/globals.vue';
import Status from '../global/status.vue';
import { User } from '../../types/users';
import { Campaign } from '../../types/filters';
import { AdPerformance, RecentAdvertiser } from '../../store/modules/customer/types';
import validateComponent, { validateComponentProps } from '../../lib/home/validateComponent';
import validateSection from '../../lib/home/validateSection';
import ToolbarC360 from '../components/toolbar/toolbarC360.vue';
import SidebarC360 from '../components/toolbar/menus/sidebarC360.vue';
import FooterC360 from '../global/footerC360.vue';
import advertiserBarC360 from '../components/toolbar/advertiserBarC360.vue';
import projectVersion from '../global/projectVersion.vue';
import utils from '../../util';
import statusCheck from '../../mixins/statusCheck';
import _clone from 'lodash.clonedeep';
import { C360Provider, defineAbilityFor } from '@c360/ui';
import orderController from '../order/orderController.vue';
import util from "@/util";

let mutations: () => void;
const debugMissingData = false;

export default Vue.extend({
  name: 'Home',
  components: {
    FooterC360,
    fileBasedLayout,
    ToolbarC360,
    AppGlobals,
    Status,
    SidebarC360,
    advertiserBarC360,
    projectVersion,
    C360Provider,
    orderController,
  },
  mixins: [statusCheck],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (): any => ({
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
    selectedUser: {},
    currentPassword: null,
    passwordToUpdate: '',
    error: '',
    success: '',
    loading: true,
    users: null,
    editedItem: {},
    editPwModal: false,
    currentSection: null,
    lastSelectedSection: null,
    editPwForced: false,
    nothingToRender: false,
    liveRecentAdvertisers: [], // localstorage is hard to "watch". this allows the UI to detect changes and update
    isCustomRange: false,
    lastAvertiserId: null, // keep track if we need to reset some data
    aBitAfterDoneLoading: false, // wait a bit after we're ready, to prevent redraws
    agencyPartner: null,
    isTemplateLoaded: false,
    refreshToken: '',
    configData: {
      whatIsNewDialog: {
        page: '',
      },
    },
  }),
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path() {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    classForVersion(): string {
      if (this.currentSection && this.currentSection.id === 'summary') return 'ml-11 mt-n15 pb-2';
      if (!this.$route.query.id && this.aBitAfterDoneLoading) return 'hidden-sm-and-up';
      if (this.isOrderPages) return 'ml-5 pb-2';
      return 'ml-5 mt-n5 pb-2';
    },
    isOrderPages(): boolean {
      return ['orderlist', 'ordersummary'].includes(this.$route?.query?.tab);
    },
    layoutSections() {
      if (!this.$store.state.customer?.dynamicLayout?.layoutCustomizations?.config) {
        // eslint-disable-next-line no-console
        console.log('missing layout section', this.$store.state.customer.dynamicLayout);
        return null;
      }
      return [this.$store.state.customer.dynamicLayout.layoutCustomizations.config];
    },
    userEmail(): string | null {
      return this.isLanding ? this.$store.getters.user.email : null;
    },
    layoutName(): string {
      return this.$store.state.customer.dynamicLayout?.layoutCustomizations.PropertyId;
    },
    layoutTactic(): string {
      return this.$store.state.customer.dynamicLayout?.layoutCustomizations.tactic;
    },
    layoutAgency(): string {
      return this.$store.state.customer.dynamicLayout?.layoutCustomizations.agencyName || this.agencyPartner;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    validSections(): Array<LayoutSection> {
      return this.$store.state?.customer?.currentDashboard?.validatedProductSections || [];
    },
    isLoading(): boolean {
      if (this.$store.state.customer.adBlockDetected) {
        return false;
      }
      let summaryLoading = false;
      if (this.currentSection?.id === 'summary') {
        summaryLoading = this.$store.state.customer.loadingSummaryPerformance;
      }
      return (
        this.loading ||
        this.$store.state.customer.waitingForAuth ||
        this.$store.state.customer.loadingLayouts ||
        this.$store.state.customer.loadingAdPerformance ||
        this.$store.state.customer.loadingShareContent ||
        summaryLoading
        // || this.$store.getters.networkActivity  <- this is to show a 'small' activity indicator, but let the page render. if there, it causes the file-layout to re-render on every switch of network activity flag
      );
    },
    canRenderContent(): boolean {
      if (this.isLanding) {
        return (
          !this.isLoading &&
          this.aBitAfterDoneLoading &&
          this.layoutSections &&
          !this.errorMessage &&
          this.isTemplateLoaded &&
          !this.nothingToRender &&
          !this.advertiserSelected
        );
      } else {
        return (
          !this.isLoading &&
          this.aBitAfterDoneLoading &&
          this.advertiserSelected &&
          this.layoutSections &&
          !this.errorMessage &&
          !this.nothingToRender
        );
      }
    },
    canExport(): boolean {
      return this.validComponents[0]?.components?.length > 0;
    },
    isLanding(): boolean {
      return this.$route.query.recent === 'true' || this.$route.query.tab === 'home';
    },
    errorMessage(): string | null {
      if (
        this.$store.state.customer.advertisers &&
        this.$store.state.customer.advertisers.list &&
        this.$store.state.customer.advertisers.list.error
      ) {
        return `Error: ${this.$store.state.customer.advertisers.list.error}`;
      }
      if (
        this.$store.state.customer.adPerformance.error &&
        this.$store.state.customer.adPerformance.error.toLowerCase() !== 'empty'
      ) {
        return `Error: ${this.$store.state.customer.adPerformance.error}`;
      }
      return null;
    },
    layoutCacheKey(): string {
      // some filters trigger re-validating the avaible tabs, other do not
      const data = this.$store.state.customer.adPerformance as AdPerformance;
      const key = `${data.PRPPID}:${data.StartDate}:${data.EndDate}:${this.$store.state.customer.currentNavTab}:${this.layoutName}`;
      return key;
    },
    adData(): AdPerformance | null {
      const data = this.$store.state.customer.adPerformance as AdPerformance;
      if (!data || !data.loaded) {
        return null;
      }
      return data;
    },
    allTimeAdData(): AdPerformance | null {
      const data = this.$store.state.customer.allTimeAdPerformance as AdPerformance;
      if (!data || !data.loaded) {
        return null;
      }
      return data;
    },
    advertiserSelected(): boolean {
      return this.$store.state.customer.selection.advertiserId;
    },
    validComponents(): Array<ValidComponents> {
      if (!this.currentSection || !this.currentSection.components) {
        return [];
      }
      let componentList = [];
      try {
        componentList = this.currentSection?.components.map((components: ValidComponents): ValidComponents => {
          components?.components.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (section: any): any => {
              const props: validateComponentProps = {
                element: section,
                adData: this.adData,
                allTimeAdData: this.allTimeAdData,
                campaignAdPerformance: this.$store.state.customer?.campaignAdPerformance,
                debugMissingData,
              };
              return validateComponent(props).validInCurrentSelection;
            },
          );
          return components;
        });
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.log('validComponents', exp);
      }
      return componentList;
    },
    isSinclair(): boolean {
      return utils.isSinclair(this.Theme);
    },
    recentAdvertisers(): RecentAdvertiser[] | null {
      if (!this.$store.getters.user.auth) {
        return [];
      }
      if (this.liveRecentAdvertisers && this.liveRecentAdvertisers.length > 0) {
        return this.liveRecentAdvertisers;
      }

      const key = `${this.$store.getters.user.email}:recentAdvertisers`;
      const recentStr: string | null = localStorage.getItem(key);
      if (!recentStr) {
        return null;
      }
      try {
        const recent: RecentAdvertiser[] = JSON.parse(recentStr).map(adv => {
          if (adv.name || adv.id) {
            return { Name: adv.name, PropertyId: adv.id };
          }
          return adv;
        });
        return recent;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('recentAdvertisers', err);
      }
      return null;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
    isLoading: {
      handler(newState: boolean, oldState: boolean): void {
        if (newState && !oldState) {
          // when going from false to true, reset aBitAfterDoneLoading to false, and set it to true after a second
          this.aBitAfterDoneLoading = false;
          setTimeout(() => {
            this.aBitAfterDoneLoading = true;
          }, 1000);
        }
      },
      immediate: true, // the only watcher that should kick in on page load
    },
    '$route.query.view': {
      handler(newView: string, oldView: string): void {
        if (!newView || newView !== oldView) {
          this.readQueryString();
        }
      },
      immediate: true, // the only watcher that should kick in on page load
    },
    '$route.query.id': {
      handler(newID: string, oldID: string): void {
        if (newID && newID !== oldID && !this.$route.query.t) {
          // replacing the 't' token by the right parameters, no need to refresh the page
          if (!this.$route.query.t) {
            this.readQueryString();
          }
        }
      },
      immediate: false,
    },
    '$route.query.daterange': {
      handler(newPeriod: string, oldPeriod: string): void {
        if (newPeriod !== oldPeriod) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.startdate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.enddate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.campaigndaterange': {
      handler(newPeriod: string, oldPeriod: string): void {
        if (newPeriod !== oldPeriod) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.campaignstartdate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.campaignenddate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.types': {
      handler(newTypes: string, oldTypes: string): void {
        if (newTypes !== oldTypes) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.campaigns': {
      handler(newCampaigns: string, oldCampaigns: string): void {
        if (newCampaigns !== oldCampaigns) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.tab': {
      async handler(): Promise<void> {
        this.readQueryString();
      },
      immediate: false,
    },
    '$route.query.recent': {
      async handler(): Promise<void> {
        this.clearAdvertiser();
      },
      immediate: false,
    },
    '$route.query.viewCampaigns': {
      async handler(newCampaigns: string, oldCampaign: string): Promise<void> {
        if (newCampaigns && newCampaigns !== oldCampaign) {
          if (
            this.$store.state.customer.allTimeCampaigns.loaded &&
            this.$store.state.customer.allTimeCampaigns.campaignlist &&
            Array.isArray(this.$store.state.customer.allTimeCampaigns.campaignlist) &&
            this.$store.state.customer.allTimeCampaigns.campaignlist.length > 0
          ) {
            const newCampaignsAsList = newCampaigns.split(',');
            const campaigns = this.$store.state.customer.allTimeCampaigns.campaignlist.filter(
              (c: Campaign) =>
                newCampaignsAsList.includes(c.id) &&
                c.CampaignType.toLowerCase() === utils.dataKeyBySectionIdMap(this.$route.query.tab).toLowerCase(),
            );
            if (campaigns) {
              this.$store.dispatch('setSelectedCampaigns', campaigns);
              return;
            }
          } else {
          }
          // check in non all time campaigns, shouldn't be needed though
          if (
            this.$store.state.customer.campaigns?.loaded &&
            this.$store.state.customer.campaigns.campaignlist &&
            Array.isArray(this.$store.state.customer.campaigns.campaignlist) &&
            this.$store.state.customer.campaigns.campaignlist.length > 0
          ) {
            const campaigns = this.$store.state.customer.campaigns.campaignlist.filter(
              (c: Campaign) =>
                newCampaigns.includes(c.id) &&
                c.CampaignType.toLowerCase() === utils.dataKeyBySectionIdMap(this.$route.query.tab).toLowerCase(),
            );
            if (campaigns) {
              this.$store.dispatch('setSelectedCampaigns', campaigns);
              return;
            } else {
            }
          } else {
          }
        }
      },
      immediate: true,
    },
    '$route.query.viewCampaignType': {
      handler(newCampaignType: string, oldCampaignType: string): void {
        if (newCampaignType && newCampaignType !== oldCampaignType) {
          if (
            this.$store.state.customer.allTimeCampaigns.loaded &&
            this.$store.state.customer.allTimeCampaigns.campaignlist &&
            Array.isArray(this.$store.state.customer.allTimeCampaigns.campaignlist) &&
            this.$store.state.customer.allTimeCampaigns.campaignlist.length > 0
          ) {
            const campaigns = this.$store.state.customer.allTimeCampaigns.campaignlist.filter((c: Campaign) => {
              return c.CampaignType === newCampaignType;
            });

            if (campaigns) {
              this.$store.dispatch('setSelectedCampaigns', campaigns);
              return;
            } else {
            }
          }
        }
      },
      immediate: true,
    },
    '$route.query.summarydaterange': {
      handler(newPeriod: string, oldPeriod: string): void {
        if (newPeriod !== oldPeriod) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.summarystartdate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.summaryenddate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
  },
  created(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).vueApp = this;

    this.$on('change-pw-modal', () => {
      this.editPwForced = false;
      this.editPwModal = !this.editPwModal;
    });
    this.getUser();
  },
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken || '';
    if (this.$route.query.period) {
      // const { period, ...cleanQuery } = this.$route.query;
      // remove the daterange for summary page
      // this.$router.replace({
      //   query: { ...cleanQuery, daterange: period },
      // });
      return;
    }

    if (
      this.$store.state.customer.selection &&
      this.$store.state.customer.selection.advertiserId &&
      (!this.$route.query || !this.$route.query.id)
    ) {
      // eslint-disable-next-line no-console
      console.info('empty query, reset dashboard');
      this.$store.dispatch('resetCustomer');
      this.$store.dispatch('resetFilters');
    }

    if (this.$route?.query?.recent) {
      this.clearAdvertiser();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutations = this.$store.subscribe((mutation: any) => {
      try {
        let daterange = this.$route.query.daterange || this.$store.state.customer.selection.daterange;
        this.isCustomRange = daterange === 'customRange'; // save so can be reset for setSelection
        if (this.isCustomRange) {
          daterange = ''; // replace period/rangetype so that Vicky can handle date range picker
        }
        switch (mutation.type) {
          case 'SET_CAMPAIGNS':
            if (this.$route.query.viewCampaigns && mutation.payload.loaded) {
              const list = mutation.payload.campaignlist;
              const queryCampaigns = this.$route.query.viewCampaigns.split(',');
              if (list && Array.isArray(list) && list.length > 0) {
                const campaigns = list.filter((c: Campaign) => queryCampaigns.includes(c.id));
                if (campaigns && campaigns.length > 0) {
                  this.$store.dispatch('setSelectedCampaigns', campaigns);
                }
              }
            }
            break;
          case 'RELOAD_CAMPAIGNS':
            this.debouncedLoadViewCampaigns(true);
            break;
          case 'SET_SELECTED_CAMPAIGNS':
            this.debouncedLoadViewCampaigns(false);
            break;
          case 'SET_SELECTION':
            this.debouncedLoadViewCampaigns(false);

            if (!this.$store.state.customer.summaryPerformance?.loaded) {
              // go fetch summary data as early as possible
              const sections = this.layoutSections;
              const selection = this.$store.state?.customer?.selection;
              if (selection?.advertiserId && selection?.daterange && Array.isArray(sections) && sections.length > 0) {
                if (sections[0].id === 'summary') {
                  this.$store.dispatch('getSummaryPerformance', selection).catch((error: Error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                  });
                }
              }
            }
            break;
          case 'SET_ADVERTISERS':
            if (!this.$route.query.id) {
              this.doneLoading();
            }
            break;
          case 'UPDATE_FILTERS':
            this.debouncedGetAdPerformance(
              daterange,
              null,
              this.$route.query.startdate || this.$store.state.customer.selection.startdate || '2020-01-05',
              this.$route.query.enddate || this.$store.state.customer.selection.enddate || '2020-01-23',
            );
            break;
          case 'UPDATE_CAMPAIGNS':
            this.debouncedGetAdPerformance(
              daterange,
              null,
              this.$route.query.startdate || this.$store.state.customer.selection.startdate || '2020-01-05',
              this.$route.query.enddate || this.$store.state.customer.selection.enddate || '2020-01-23',
            );
            break;
          default:
            break;
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('subscribed', exp, mutation);
      }
    });
  },
  beforeDestroy(): void {
    this.leaveEditMode();
    mutations();
  },
  methods: {
    hideExpectedErrors(errorMessage) {
      // DASH-5189 When there is no campaigns for specific product, we should not show error message
      const noDataError = !this.isOrderPages && !this.isLoading && this.aBitAfterDoneLoading && !this.canRenderContent && this.$route.query.id;
      const err = 'Error: Request failed with status code 500';
      return noDataError && errorMessage === err;
    },
    currentDate(): string {
      return format(new Date(), 'yyyy-MM-dd');
    },
    loadViewCampaigns(reload: boolean): void {
      const isAdPerformanceLoaded = this.$store.state.customer.adPerformance?.loaded;
      const singleCampaignLoaded = this.$store.state.customer.adPerformance?.CampaignList?.length === 1;
      const isCurrentCampaignLoaded = this.$store.state.customer.adPerformance?.CampaignList?.find((c: Campaign) => c.CampaignId === this.$route.query.viewCampaigns);
      // Now we are not loading getAdPerformance for initial loading tactic page without selected campaigns, so we need to check if the campaign is already loaded in campaignAdPerformance as well
      const isCampaignAdPerformanceForCurrentCampaignLoaded = this.$store.state.customer.campaignAdPerformance?.CampaignList?.find((c: Campaign) => c.CampaignId === this.$route.query.viewCampaigns);
      if (!reload && isCampaignAdPerformanceForCurrentCampaignLoaded) {
        return;
      }
      // if current getAdPerformance includes selectedCampaign, supposed to be used only for initial loading tactic page without selected campaigns
      if (!reload && isAdPerformanceLoaded && singleCampaignLoaded && isCurrentCampaignLoaded) {
        this.$store.state.customer.campaignAdPerformance = this.$store.state.customer.adPerformance;
        return;
      }
      const isSummary = this.$route.query.tab === 'summary';
      if (
        !this.$store.state.filters.selectedCampaigns ||
        !this.$store.state.filters.selectedCampaigns.length ||
        !this.$store.state.customer.selection.advertiserId ||
        isSummary
      ) {
        return;
      }
      function getIdFromCampaign(allCampaigns, campaign) {
        allCampaigns.push(campaign.id);
        return allCampaigns;
      }
      const params = { ...this.$store.state.customer.selection };

      this.$store
        .dispatch('getCampaignAdPerformance', {
          advertiserId: params.advertiserId,
          daterange: params.daterange,
          startdate: params.startdate,
          enddate: params.enddate,
          campaigndaterange: params.campaigndaterange,
          campaignstartdate: params.campaignstartdate,
          campaignenddate: params.campaignenddate,
          creative: params.creative,
          campaigns: this.$store.state.filters.selectedCampaigns?.reduce(getIdFromCampaign, []).join(','),
          reload,
          currentNavTab: this.$route.query.tab,
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    applyCampaignTypeSelection(selection: string): Promise<void> {
      if (!selection) {
        let allTypes = [];
        if (
          this.$store.state.customer.campaigns?.campaignlist &&
          Array.isArray(this.$store.state.customer.campaigns.campaignlist)
        ) {
          allTypes = this.$store.state.customer.campaigns.campaignlist.map(
            (campaign: Campaign) => campaign.CampaignType,
          );
        }
        this.$store.dispatch('setCampaignTypesFiltered', false);
        return this.$store.dispatch('setSelectedCampaignTypes', allTypes);
      }
      const a = selection.split(',');
      this.$store.dispatch('setCampaignTypesFiltered', true);
      return this.$store.dispatch('setSelectedCampaignTypes', a);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    applyCampaignSelection(selection: string): Promise<[any, any]> {
      const allIds = this.$store.getters.selectableCampaignIDs;
      if (!selection) {
        return Promise.all([
          this.$store.dispatch('setCampaignFiltered', false),
          this.$store.dispatch('setSelectedCampaignIDs', allIds),
        ]);
      }
      if (selection.length > 0 && selection[0] === '-') {
        const a = selection.substr(1).split(',');
        const selected = allIds.filter(x => !a.includes(x));
        return Promise.all([
          this.$store.dispatch('setCampaignFiltered', true),
          this.$store.dispatch('setSelectedCampaignIDs', selected),
        ]);
      }
      const a = selection.split(',');
      return Promise.all([
        this.$store.dispatch('setCampaignFiltered', true),
        this.$store.dispatch('setSelectedCampaignIDs', a),
      ]);
    },
    debouncedLoadViewCampaigns(booleanValue?: boolean): void {
      setTimeout(() => {
        this.loadViewCampaigns(booleanValue);
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debouncedGetAdPerformance(daterange?: string, advertiser?: any, startdate?: string, enddate?: string): void {
      clearTimeout(this.getAdPerformanceTimer);
      this.getAdPerformanceTimer = setTimeout(() => {
        this.getAdPerformance(daterange, advertiser, startdate, enddate);
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAdPerformance(daterange?: string, advertiser?: any, startdate?: string, enddate?: string): Promise<void> {
      return new Promise((resolve, reject) => {
        // if an advertiser is not passed in, get the info from the store
        if (!advertiser) {
          advertiser = {
            StationList: this.$store.state.customer.selection.SFLT,
            Name: this.$store.state.customer.selection.advertiserName,
            PropertyId: this.$store.state.customer.selection.advertiserId,
            CreativeList: this.$store.state.customer.selection.creative,
          };
        }
        let campaigns = '';
        if (
          this.$store.state.customer.campaigns &&
          this.$store.state.customer.campaigns.campaignlist &&
          this.$store.state.filters.current
        ) {
          // const campaignsState = this.$store.state.customer.campaigns as CampaignResults;

          // default to all time if no date range selected
          if (!daterange && !this.isCustomRange) {
            daterange = 'alltime';
          } else if (this.isCustomRange && !daterange) {
            daterange = 'customRange'; // reset bc originally set to '' for api
          }
          if (
            this.$store.state.customer.campaigns &&
            Array.isArray(this.$store.state.customer.campaigns.campaignlist)
          ) {
            campaigns = this.$store.state.customer.campaigns.campaignlist
              .map((campaign: { id: string }) => {
                const { id } = campaign;
                return id;
              })
              .join(',');
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('getAdPerformance, no campaigns');
        }
        this.$store
          .dispatch('setSelection', {
            SFLT: advertiser.StationList,
            advertiserName: advertiser.Name,
            advertiserId: advertiser.PropertyId,
            creative: advertiser.CreativeList,
            daterange,
            campaigns,
            startdate,
            enddate,
          })
          .then(() => {
            resolve();
          })
          .catch(reject);
      });
    },
    setQueryView(): Promise<void> {
      this.isTemplateLoaded = false;

      return new Promise((resolve, reject) => {
        if (!this.$store.getters.user.auth) {
          reject(new Error('not authorized'));
          return;
        }
        const agency = utils.getAgencyFromURL();
        let tactic;
        if (this.isLanding) {
          tactic = 'home';
        } else {
          tactic = this.$route.query?.tab || 'orderlist';
        }
        // get  default layout
        this.$store
          .dispatch('getLayoutsGQL', {
            agency,
            advertiserId: this.$route.query?.id,
            tactic,
            userEmail: this.userEmail,
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((layout: any): void => {
            if (layout) {
              const layoutCopy = { layoutCustomizations: _clone(layout.layoutCustomizations) };
              const defaultLayoutCopy = { layoutCustomizations: _clone(layout.defaultLayout) };
              this.$store
                .dispatch('setLayout', { layoutCustomizations: layout.layoutCustomizations })
                .then(() => {
                  this.$store.dispatch('setLayoutSource', layoutCopy).then(resolve).catch(reject);
                  this.$store.dispatch('setDefaultLayout', defaultLayoutCopy);
                  this.isTemplateLoaded = true;
                })
                .catch(reject);
              return;
            }
            reject(new Error('no layout for user'));
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSelection(query: any): Promise<void> {
      return new Promise((resolve, reject) => {
        if (!this.$store.getters.user.auth) {
          reject(new Error('not authorized'));
          this.doneLoading();
          return;
        }
        // if (!this.isGQL && !this.layoutAgency) {
        //   reject(new Error('failed to load layout'));
        //   this.doneLoading();
        //   return;
        // }
        if (!query) {
          query = this.$route.query;
        }
        let advertiserId = '';
        if (query?.id) {
          if (Array.isArray(query.id)) {
            advertiserId = query.id[0] || '';
          } else {
            advertiserId = query.id;
          }
        }
        if (this.lastAvertiserId && this.lastAvertiserId !== advertiserId) {
          this.$store.dispatch('resetCustomer');
        } else {
          this.$store.dispatch('resetAdPerformance');
        }
        this.lastAvertiserId = advertiserId;

        if (this.isOrderPages) {
          Promise.all([
            this.$store.dispatch('advertiser/getAdvertiserInfo', { id: this.$route.query.id }),
            this.$store.dispatch('getProductsForAdvertiser', this.$route.query.id),
          ]).then(async results => {
            const resp = results[0];
            const resp2 = results[1];
            this.setValidSections();
            this.doneLoading();
            resolve();
            return;
          });
        }
        // if landing =>
        else if (this.isLanding) {
          try {
            this.setValidSections();
          } catch (error) {
            console.log('setSelection', error);
          }
          this.doneLoading();
          resolve();
        } else {
          const advertiserId = this.$route.query.id;
          const isSummary = this.$route.query.tab === 'summary';
          const hasSelectedCampaigns = !!this.$route.query.viewCampaigns;
          const currentNavTab = this.$route.query.tab;
          const tactic = util.dataKeyBySectionIdMap(currentNavTab);
          const daterangeParams = {
            daterange: 'alltime',
            startdate:null,
            enddate: null,
          };
          if (isSummary) {
            daterangeParams.daterange = this.$route.query.summarydaterange || 'alltime';
            daterangeParams.startdate = this.$route.query.summarystartdate || null;
            daterangeParams.enddate = this.$route.query.summaryenddate || null;
          } else {
            daterangeParams.daterange = this.$route.query.daterange || 'alltime';
            daterangeParams.startdate = this.$route.query.campaignstartdate || null;
            daterangeParams.enddate = this.$route.query.campaignenddate || null;
            daterangeParams.campaigndaterange = this.$route.query.campaigndaterange || 'alltime';
            daterangeParams.campaignstartdate = this.$route.query.campaignstartdate || null;
            daterangeParams.campaignenddate = this.$route.query.campaignenddate || null;
          }
          Promise.all([
            this.$store.dispatch('advertiser/getAdvertiserInfo', { id: this.$route.query.id }),
            this.$store.dispatch('getProductsForAdvertiser', this.$route.query.id),
            (async () => {
              // For Advertiser (Product) Summary only.
              if (advertiserId && isSummary) {
                return this.$store.dispatch('getSummaryPerformance', {
                  advertiserId,
                  ...daterangeParams,
                });
              }
              // For Any Tactic (Product) with selected campaign(s)
              else if (advertiserId && hasSelectedCampaigns) {
                const campaignsWithTactic = this.$route.query.viewCampaigns?.split(',').map(c => ({
                  id: c,
                  type: tactic,
                }));
                return this.$store.dispatch('getCampaignAdPerformance', {
                  advertiserId,
                  ...daterangeParams,
                  campaignsWithTactic,
                  campaigns: this.$route.query.viewCampaigns,
                  ignoreSelection: true,
                });
              }
              // For Any Tactic (Product) without selected campaign(s)
              // actually for first selected campaign by default
              else if (advertiserId && tactic) {
                return this.$store.dispatch('getAdPerformance', {
                  advertiserId,
                  ...daterangeParams,
                  tactic,
                  reload: true
                });
              }
            })(),
            (async () => {
              // Used in any tactic to make request asap
              if (advertiserId) {
                return this.$store.dispatch('getCampaigns', {
                  advertiserId: advertiserId,
                  ...daterangeParams,
                  campaigns: [],
                  cache: false,
                });
              }
            })(),
          ])
            .then(async results => {
              const resp = results[0];
              const resp2 = results[1];
              if (!resp || !resp.data) {
                reject(new Error('this account is not available'));
                return;
              }
              if (resp.data.ErrorMessage) {
                // eslint-disable-next-line no-console
                console.log('getAdvertiserInfo error', resp.data);
                reject(new Error('this account is not available'));
                return;
              }
              if (!resp2) {
                reject(new Error('No data for this advertiser'));
                return;
              }
              if (resp2.error) {
                reject(new Error(resp2.error));
                return;
              }
              this.setValidSections(); // load the list of valid sections based on the advertiser id
              const advertiser = resp.data;
              this.agencyPartner = advertiser.AgencyPartner;
              // if the current layout does not match the advertiser agency partner, get correct value from the advertiser.
              // if (!this.isGQL && this.layoutAgency !== this.agencyPartner) {
              //   this.readQueryString();
              //   return;
              // }
              if (!advertiserId) {
                this.$store
                  .dispatch('setSelection', {
                    SFLT: [],
                    advertiserName: null,
                    advertiserId: null,
                    daterange: null,
                    view: query?.view,
                    creative: [],
                    campaigns: null,
                    startdate: null,
                    enddate: null,
                  })
                  .then(() => {
                    this.doneLoading();
                    const { daterange } = query;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
                    const { dmas: _1, ...cleanQuery } = this.$route.query;
                    this.$router.replace({
                      query: {
                        ...cleanQuery,
                        id: advertiser.PropertyId,
                        daterange,
                      },
                    });
                  });
                resolve();
                return;
              }
              /* period can come from multiple sources. we prioritize in this order:
            1. what was passed in
            2. the default returned from the API
            3. none
            */
              let daterange = '';
              if (query.daterange) {
                daterange = query.daterange;
              } else if (
                this.$store.state.customer.campaigns &&
                this.$store.state.customer.campaigns.defaultdaterange
              ) {
                daterange = this.$store.state.customer.campaigns.defaultdaterange;
              } else {
                daterange = 'alltime';
              }
              // set the start and end dates based on the current campaign data from getCampaigns api
              let startdate = this.currentDate();
              let enddate = this.currentDate();
              if (query.startdate) {
                startdate = query.startdate;
              }
              if (query.enddate) {
                enddate = query.enddate;
              }
              let campaigndaterange = '';
              let campaignstartdate = this.currentDate();
              let campaignenddate = this.currentDate();
              const updatedQuery = this.$route.query;
              if (updatedQuery.campaigndaterange) {
                campaigndaterange = updatedQuery.campaigndaterange;
              }
              if (updatedQuery.campaignstartdate) {
                campaignstartdate = updatedQuery.campaignstartdate;
              }
              if (updatedQuery.campaignenddate) {
                campaignenddate = updatedQuery.campaignenddate;
              }
              const params = {
                advertiserId: advertiser.PropertyId,
                daterange,
                startdate,
                enddate,
                campaigns: [],
              };
              // DT-363, passing campaign dates to /getCampaigns
              if (campaigndaterange === 'customRange') {
                params.daterange = campaigndaterange;
                params.startdate = campaignstartdate;
                params.enddate = campaignenddate;
              }
              // override cache if there's been a campaignList reset before nav to new tab
              const newTab = this.lastSelectedSection !== updatedQuery.tab;
              const hasCampsInStore = Array.isArray(this.$store.state.customer?.campaigns?.campaignlist);
              if (newTab && !hasCampsInStore) {
                params['cache'] = false;
              }
              this.$store
                .dispatch('getCampaigns', params)
                .then(() => {
                  startdate = this.$store.state.filters?.current?.StartDate || this.currentDate();
                  enddate = this.$store.state.filters?.current?.EndDate || this.currentDate();
                  // this feels not completely right
                  if (campaigndaterange === 'customRange') {
                    this.$store.dispatch('setSelection', {
                      campaigndaterange: campaigndaterange,
                      campaignstartdate: campaignstartdate,
                      campaignenddate: campaignenddate,
                    });
                  } else {
                    this.$store.dispatch('setSelection', {
                      campaigndaterange: null,
                      campaignstartdate: null,
                      campaignenddate: null,
                      startdate,
                      enddate,
                    });
                  }
                  const qCampaigns = query.campaigns;
                  const qtypes = query.types;
                  this.applyCampaignTypeSelection(qtypes)
                    .then(() => {
                      this.applyCampaignSelection(qCampaigns)
                        .then(() => {
                          this.$store
                            .dispatch('applyCampaignSelection')
                            .then(() => {
                              clearTimeout(this.getAdPerformanceTimer);
                              this.getAdPerformance(daterange, advertiser, startdate, enddate)
                                .then(resolve)
                                .catch(reject);
                            })
                            .catch(reject);
                        })
                        .catch(reject);
                    })
                    .catch(reject);
                })
                .catch(reject);
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log('getAdvertiserInfo error', err);
              reject(new Error('No data for this advertiser'));
              this.doneLoading();
            });
        }
      });
    },
    doneLoading(): void {
      clearTimeout(this.doneLoadingTimer);
      this.doneLoadingTimer = setTimeout(() => {
        this.loading = false;
      }, 100);
      setTimeout(() => {
        this.configData = {
          whatIsNewDialog: {
            page: `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`,
          },
        };
      }, 100);
    },
    readQueryString(): void {
      this.loading = true;
      // because multiple watchers trigger these, we debounce to use only the last call
      clearTimeout(this.readQueryStringTime);
      this.readQueryStringTime = setTimeout(() => {
        if (!this.isLanding && !this.$route.query.t && !this.$route.query.id) {
          this.doneLoading();
          return;
        }
        this.loading = true;
        this.currentSection = undefined; // make sure we don't try to refresh the current layout while we load the latest data
        this.setQueryView()
          .then(() => {
            // if tab is 'summary', remove customRange
            if (this.$route.query.tab === 'summary') {
              const q = {
                id: this.$route.query?.id,
                tab: 'summary',
                daterange: this.$route.query?.daterange || undefined,
              };
              // if (Object.keys(this.$route.query).length > 2) {
              //   this.$router.replace({ query: q });
              // }
              return this.setSelection({ ...q, view: this.layoutTactic });
            } else if (this.isLanding) {
              return this.setSelection({ view: this.layoutTactic });
            }
            return this.setSelection({ ...this.$route.query, view: this.layoutTactic });
          })
          .then(() => {
            this.setCurrentSection({
              id: null,
              campaignId: null,
              replaceUrlParam: true,
            });
          })
          .catch(err => {
            if (err && err.message !== 'no valid section') {
              this.$store.dispatch('showError', err.message);
              // eslint-disable-next-line no-console
              console.log('setQueryView error', err);
            }
          })
          .finally(() => {
            this.doneLoading();
          });
      }, 100);
    },
    setValidSections(): Array<LayoutSection> {
      // get a list of strings returned from an api with valid products for this advertiers
      const validTactics: Array<string> =
        this.$store.state?.customer?.currentDashboard?.products?.map(product => product.id) || [];
      // TODO: add these into API
      validTactics.push('home');
      validTactics.push('homeController');
      validTactics.push('orderlist');
      validTactics.push('ordersummary');

      if (!validTactics) {
        // eslint-disable-next-line no-console
        console.error('Missing valid tactics');
        return;
      }

      // load layout config file from store
      const layout = this.layoutSections;
      if (!layout) {
        return [];
      }
      // combine and filter the layout sections based on the valid products for this advertiser
      const validatedSections = layout.reduce(
        (all: Array<LayoutSection>, section: LayoutSection): Array<LayoutSection> => {
          if (validateSection(section, validTactics)) all.push(section);
          return all;
        },
        [],
      );
      // set the validated sections in the store
      this.$store.dispatch('setValidatedProductSections', validatedSections);
    },
    setCurrentSection(current: CurrentSection): Promise<void> {
      const { id, campaignId, replaceUrlParam, retries, hasSummary, tactic } = current;
      let sectionId = (id || '').toLowerCase();
      const layout = this.layoutSections;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { dmas, campaigns, types, viewCampaigns, viewCampaignType, tab, summaryView, orderId, product, ...cleanQuery } = this.$route.query;
      const isFiltering = !!(types || campaigns);

      // NOTE: when filters/date ranges change, which sections are valid may change
      // we're letting it be 'no data' if we're filtering by types though, it could be that we unselected all
      if ((!this.validSections || this.validSections.length === 0) && !isFiltering && !this.isLanding) {
        const tries = retries || 10;
        if (tries > 0) {
          const delayed = t => new Promise(resolve => setTimeout(resolve, t));
          return delayed(250).then(() => {
            return this.setCurrentSection({
              id,
              campaignId,
              replaceUrlParam,
              retries: tries - 1,
              hasSummary,
            });
          });
        }
        return new Promise((resolve, reject) => {
          this.nothingToRender = true;
          reject(new Error('no valid section'));
        });
      } else if (isFiltering) {
      }
      if (!this.validSections || this.validSections.length === 0) {
        this.doneLoading();
        return new Promise(resolve => {
          resolve();
        });
      }
      // no id means its being called from a watch
      if (!sectionId) {
        if (!this.currentSection) {
          // default (on load or new advertiser)
          if (tab) {
            // handle use case where there is an active tab in the url
            // try to find a corresponding section or use the first one

            const matchingSection = this.validSections.find(section => section.id === tab);
            if (matchingSection) this.currentSection = matchingSection;
            else {
              [this.currentSection] = this.validSections;
            }
          } else {
            [this.currentSection] = this.validSections;
          }
          sectionId = this.currentSection.id;
        } else {
          // if we are filtering by campaign types, assume the current tab/section is right
          if (tab && isFiltering) {
            this.currentSection = layout.find((section: LayoutSection) => section.id === tab);
          } else {
            const validSection = this.validSections.find((section: LayoutSection) => section.id === tab);
            if (validSection) {
              this.currentSection = validSection;
            } else {
              [this.currentSection] = this.validSections;
            }
          }
          sectionId = this.currentSection.id;
        }
      } else {
        // change coming from tab btn
        if (this.currentSection && sectionId === this.currentSection.id) {
          return new Promise(resolve => {
            this.nothingToRender = false;
            resolve();
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.currentSection = this.validSections.find((section: any) => section.id === sectionId);
      }
      return this.$store.dispatch('setCurrentSection', this.currentSection).then(() => {
        // use incoming tactic tab if set.
        cleanQuery.tab = tactic || sectionId;
        // set campaign Id if passed.
        if (campaignId) cleanQuery.viewCampaigns = campaignId;
        this.nothingToRender = false;
        this.doneLoading();
        let navNeeded;
        if (!this.$route.query.tab && cleanQuery.tab) {
          navNeeded = 'replace';
        } else if (this.$route.query.tab !== cleanQuery.tab) {
          navNeeded = replaceUrlParam ? 'replace' : 'push';
        }

        if (campaignId) {
          cleanQuery.viewCampaigns = campaignId;
        }

        // if summary view needs to be first.
        if (hasSummary) {
          cleanQuery.summaryView = true;
          delete cleanQuery.viewCampaigns;
          navNeeded = 'replace';
        } else {
          delete cleanQuery.daterange;
          delete cleanQuery.campaigndaterange;
          delete cleanQuery.campaignstartdate;
          delete cleanQuery.campaignenddate;
        }

        if (navNeeded) {
          this.$router[navNeeded]({
            query: cleanQuery,
          }).catch((error: Error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
        }
      });
    },
    async selectAdvertiser(selected: RecentAdvertiser): Promise<void> {
      if (!selected) {
        return;
      }

      if (!selected || this.$route.query.id === selected.PropertyId) {
        return;
      }
      // this.$store.dispatch('resetDateRanges');
      this.$store.dispatch('resetProductSections');
      this.$store.dispatch('resetAdPerformance');
      this.$store.dispatch('resetAdvertiserPolygons');
      await this.$store.dispatch('resetCampaigns');

      this.$router.replace({
        query: { id: selected.PropertyId, tab: 'orderlist' },
      });

      const key = `${this.$store.getters.user.email}:recentAdvertisers`;
      const recentStr: string | null = localStorage.getItem(key);
      if (recentStr) {
        try {
          let recent: Array<RecentAdvertiser> = JSON.parse(recentStr).map(ra => {
            // scrub to use updated property names
            if (ra.name || ra.id || ra.PropetyId) {
              return { Name: ra.name, PropertyId: ra.id };
            } else {
              return ra;
            }
          });

          // make sure it's not already there
          const match = recent.find((a: RecentAdvertiser) => a.PropertyId === selected.PropertyId);
          if (match) return;

          recent = [{ Name: selected.Name, PropertyId: selected.PropertyId, Agency: selected.Agency }, ...recent];
          recent = Array.from(new Set(recent));

          // keep only first 5
          if (recent.length > 5) {
            recent = recent.slice(0, 5);
          }
          this.liveRecentAdvertisers = recent;

          localStorage.setItem(key, JSON.stringify(recent));
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('recentAdvertisers', err);
        }
      } else {
        this.liveRecentAdvertisers = [
          { Name: selected.Name, PropertyId: selected.PropertyId, Agency: selected.Agency },
        ];
        localStorage.setItem(
          key,
          JSON.stringify([{ Name: selected.Name, PropertyId: selected.PropertyId, Agency: selected.Agency }]),
        );
      }
      // }
    },
    removeRecentAdvertiser(advertiserName: string): void {
      const key = `${this.$store.getters.user.email}:recentAdvertisers`;
      const recentStr: string | null = localStorage.getItem(key);
      if (recentStr) {
        try {
          let recent: string[] = JSON.parse(recentStr);
          if (!recent.includes(advertiserName)) {
            return;
          }
          recent = recent.filter(name => name !== advertiserName);
          this.liveRecentAdvertisers = recent;
          localStorage.setItem(key, JSON.stringify(recent));
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('removeRecentAdvertiser', err);
        }
      }
    },
    generateCache(): void {
      this.$store.dispatch('generateCache');
    },
    getUser(): void {
      this.selectedUser = { ...this.$store.getters.user };
      if (this.$store.getters.user?.forcePWChange) {
        this.editPwModal = true;
        this.editPwForced = true;
      }
    },
    updateUserPw(): void {
      if (this.$refs.editPwForm.validate() && this.passwordToUpdate && this.currentPassword) {
        this.loading = true;

        this.$store
          .dispatch('updateUserPassword', {
            username: this.selectedUser.email,
            currentPassword: this.currentPassword,
            newPassword: this.passwordToUpdate,
          })
          .then(data => {
            if (data.ErrorMessage || data.error) {
              // eslint-disable-next-line no-console
              console.error('updateUser error', data);
              this.error = null;
              this.error = data.ErrorMessage || data.error;
              this.dismissAlerts();
              this.doneLoading();
            } else {
              setTimeout(() => {
                this.getUser();
                this.success = `Successfully updated user ${this.selectedUser.email}`;
                this.dismissAlerts();
                this.doneLoading();
                this.editPwModal = false;
                window.scrollTo(0, 0);
              }, 4000);
            }
          });
      }
    },
    dismissAlerts(): void {
      setTimeout(() => {
        this.error = '';
        this.success = '';
      }, 5000);
    },
    getUserByEmail(searchedEmail: string): User | null {
      if (!Array.isArray(this.users)) {
        return null;
      }
      return this.users.find((u: User) => u.email === searchedEmail);
    },
    leaveEditMode(): void {
      this.selectedUser = null;
    },
    clearAdvertiser(): void {
      this.currentSection = null;
      this.$store.dispatch('setSelection', {
        SFLT: [],
        advertiserName: null,
        advertiserId: null,
        daterange: null,
        view: null,
        creative: [],
        campaigns: null,
        startdate: null,
        enddate: null,
      });
      this.$store.dispatch('advertiser/resetAdvertiserInfo');
      this.$store.dispatch('resetProductSections');
      this.$store.dispatch('resetAdPerformance');
      this.$store.dispatch('resetAdvertiserPolygons');
      this.$store.dispatch('resetCampaigns');
      document.title = 'Analytics Dashboard';
    },
  },
});
